@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

* {
  font-family: "Nunito", sans-serif;
}

body {
  overflow: hidden;
}

.active {
  background-color: #ddd !important;
}

li {
  margin-top: 20px;
  text-align: center;
}

.userlist-table-main {
  background: #e9e5fa !important;
  height: 100vh;
}

.categoryList-tablewrap td span {
  /* padding: 5px 12px; */
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 70px;
  font-weight: 500;
  overflow: hidden;
  font-size: 13px;
}

.tableResizer_max {
  min-width: 100%;
  width: max-content;
}

.overflow-x-auto.scrollDesign.categoryList-tablewrap-outer {
  overflow-y: visible;
  overflow-x: auto;
  height: 72.8vh;
  border-radius: 10px 0 10px 10px;
}

.dataSheetHeader {
  box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.2);
}

.dataSheetHeader h4 {
  font-size: 20px;
  color: #3d4249;
  font-weight: 600;
}

.mainHeader .dataSheetHeader {
  margin-bottom: 12px !important;
  border-radius: 10px !important;
  padding: 0 !important;
}

.dataSheetHeader input {
  background: #f6f8fc;
  border-radius: 90px !important;
  font-size: 16px;
  color: #666666;
  padding: 8px 33px 8px 50px;
}

.dataSheetHeader .searchIcon {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  padding: 0 18px;
  cursor: pointer;
  width: 50px;
  height: 100%;
}


/* .logo-image {
    width: 482px;
    height: 56px;
    top: 13.91px;
    left: 151px;

} */

/* .card-container {
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 93.5vh;
    width: auto;
} */


/* Login Page Styling */

:root {
  --gradient: linear-gradient(90deg, rgba(187, 78, 158, 1) 50%, rgba(145, 89, 171, 1) 100%);
  --gradientHov: linear-gradient(90deg, rgba(145, 89, 171, 1) 50%, rgba(187, 78, 158, 1) 100%);
  --gray: #F2F2F2;
  --lightGray: #777777;
  --darkGray: #4A4A4A;
  --bgLight: #E8E8E8;
  --offBlack: #333333;
  --blue: #2EA2DD;
}

/* 1. Common Css
/* 2. Header 
/* 3. Login Page
/* 4. Tracker Page

/* Common Css */
* {
  box-sizing: border-box;
}

body {
  font-family: "Nunito", sans-serif;
}

img {
  max-width: 100%;
}

.heading5 {
  font-size: 20px;
  margin-bottom: 3px;
}

.heading6 {
  font-size: 17px;
  margin-bottom: 0px;
  font-weight: 600;
}

p {
  font-size: 15px;
}

.btn-primary {
  background: var(--gradient);
  color: var(--bs-white);
  border: 0;
  font-size: 14px;
  font-weight: 700;
  padding: 12px 20px;
  border-radius: 3px;
  text-transform: uppercase;
}

.btn-primary:hover,
.btn-primary:focus {
  background: var(--gradientHov);
  color: var(--bs-white);
}

.btn-outline-primary {
  background: var(--gradient);
  padding: 1px;
  border-radius: 3px;
  text-transform: uppercase;
  overflow: hidden;
  z-index: 0;
  border: 0;
}

.btn-outline-primary span {
  padding: 10px 24px;
  font-size: 14px;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
  display: block;
  background: var(--gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
}


.btn-outline-primary span:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  background: #ffffff;
  height: 100%;
  top: 0;
  bottom: 0;
  z-index: -1;
}

/* Header  */
.header {
  background-color: #F1F1F1;
  padding: 12px 0;
}

header {
  padding: 5px 0;
}

/* Login Page */
.loginForm {
  padding: 165px 0 0;
}

.loginForm .heading5 {
  padding-bottom: 12px;
  border-bottom: 1px solid var(--bgLight);
  margin-bottom: 25px;
}

.loginForm form {
  padding: 40px;
  box-shadow: 0px 0px 44px 0 rgb(0 0 0 / 7%);
  max-width: 470px;
}

.form-group label {
  font-size: 17px;
  color: var(--lightGray);
  margin-bottom: 10px;
  font-weight: 500;
}

.loginForm .form-group {
  margin-bottom: 20px;
}

.form-control {
  background-color: var(--gray);
  color: var(--offBlack);
  height: 40px;
  line-height: 40px;
  padding: 0 10px 0 25px;
  border-radius: 3px;
}

.form-control:focus {
  background-color: var(--gray);
}

.formBtn {
  padding-top: 10px;
  margin-bottom: 35px;
}

.formBtn button {
  width: 100%;
}

.loginForm p {
  font-size: 17px;
  color: var(--lightGray);
}

hr {
  border-color: var(--bgLight);
  margin: 22px 0;
}

.form-bottom-wrap .info {
  color: var(--blue);
  text-decoration: underline;
}

.form-bottom-wrap a {
  color: var(--lightGray);
  text-underline-offset: 1px;
  font-size: 17px;
}

.bottomDetails {
  margin-top: 25px;
}

.bottomDetails a {
  font-size: 17px;
  color: var(--offBlack);
  text-decoration: none;
  font-weight: 600;
}

.input-group .field-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  outline: 0;
  border: 0;
  padding: 0;
}

.error {
  color: #f00;
}

/* logout_button */

/* dashboard */

.dashboard {
  margin-top: 0px;
}

.layout_container .col-md-2 {
  flex: 0 0 auto;
  width: 12.666667%;
}

.layout_container .col-md-10 {
  flex: 0 0 auto;
  width: 87.333333%;
}

.side_dashboard {
  background-color: #f2f5f8;
  border-right: 1px solid #ddd;
  height: 100vh;
}

.sidebar-layout {
  padding: 10px;
}

.mainScreen_dashboard {
  background-color: #f9f9f9;
  height: 100vh;
}

.sidebarStyle {
  padding: 20px;
}

.sidebar-logo {
  width: 200px;
  margin-left: 0px;
}

.main_dashboardHeasder {
  box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

/* Add these styles to your CSS file or inline styles */

.image-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  max-height: 69vh;
  overflow: auto;
}

.image-container img {
  max-width: 100%;
  height: auto;
  margin-left: 26px;
  margin-top: 14px;
}

.gallery-image {
  width: 200px;
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
}

.category-modal1 {
  background: black;

}

.modal-header .btn-close {
  background-color: #fff;
}

.activ-col {
  margin-left: 10px;
  margin-right: 10px;
  padding: 50px;
}

.userLink_mainScreen {
  text-decoration: none;
  margin-top: 8px !important;
  text-align: center;
}

.calender {
  right: 40px;
}

.calender-main {
  display: flex;
}

/* .title-row-side {
  width: 250px;
  height: 0.62px;
  font-weight: 800;
  color: #ddd;
} */

/* progressBar */

.BootstrapProgressBar-size {
  max-width: 203px;
  margin-left: 25px;
  margin-top: 15px;
  height: 4px;
}

.BootstrapProgressBar-percentage {
  font-size: 11px;
  text-align: center;
  margin-top: 5px;
}

.BootstrapProgressBar-time {
  font-size: 11px;
  margin-top: 2px;
  margin-left: 33px;
}

.card-body {
  padding: 0;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the transparency and color as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  /* Ensure the spinner is on top of other content */
}

.spinner-container {
  position: relative;
  /* Change to relative to allow centering */
}

/* Add animation keyframes for spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Apply animation to the spinner */
.spinner-border {
  animation: spin 1s linear infinite;
  /* Adjust animation duration and timing function as needed */
}

/* Apply animation to the loading text */
.loading-text {
  animation: fadeInOut 1.5s linear infinite;
  opacity: 0;
}

@keyframes fadeInOut {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

/* dropdown button */
.dropdown-toggle::after {
  content: none;
}

/* Tooltip */

.custom-tooltip {
  background-color: #333;
  color: #fff;
  max-width: 170px;
  font-size: 11px;
  border-radius: 10px;
}

/* form_select */

.form_select_user {
  max-width: 300px;
}

.filter-section {
  margin-bottom: 20px;
}

.filter-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.select-box {
  width: 100%;
  margin-bottom: 10px;
}

.filter-title .placeholder {
  font-size: 8px;
}

.css-1jqq78o-placeholder {
  grid-area: 1/1/2/3;
  color: hsl(0, 0%, 50%);
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  font-size: 13px;
}

.group-by-text {
  color: #BB4E9E;
  text-transform: capitalize;
}

.table_row_style {
  text-transform: capitalize;
  background-color: #f4f6f9 !important;
  font-size: 15px;
  border: 1px solid #cbcbcb;
}

.table_font-style {
  font-weight: 500;
  font-size: 15px;
}

.custom-fragment {
  margin-top: 40px;
}

/* idle_time" */
.idle-image {
  background-color: #ddd;
  width: 200px;
  height: auto;
  max-width: 100%;
  margin-left: 26px;
  margin-top: 14px;
  text-align: center;
}

.idle_icon {
  font-size: 34px;
  font-weight: 500;
  line-height: 90px;
  margin-bottom: 20px;
  color: #aab2ba;
}

.idle_time {
  font-size: 16px;
  display: block;
  margin-top: -22px;
  padding-bottom: 23px;
  color: #7a8798;
}

.no_activity {
  width: 200px;
  height: auto;
  max-width: 100%;
  margin-left: 26px;
  margin-top: 61px;
  text-align: center;
  background-color: #e9edef;
  color: #7a8798;
  border-radius: 2px;
  font-size: 12px;
  padding: 12px 0 11px;
}

/* .reports-style {
  background-color: lightblue;
  border: 2px solid blue;
} */

.date_picker_styling {
  min-width: 300px !important;
  width: auto !important;
}

.first-tr:last-of-type {
  margin-bottom: 20px;
}

.scrollable-tbody {
  height: 88vh;
  overflow-y: auto;
}

.total_row_data {
  width: 0;
}

.date_range {
  display: flex;
  align-items: center;
  border: 1px solid var(--bs-border-color);
}

.date_range .calender {
  height: auto;
  right: 0;
  padding: 0 10px;
  border: 0;
}

.date_range input {
  background-color: #ffffff;
  border: 0;
  padding: 0 10px;
}

.date_range input:focus {
  box-shadow: none;
  background: transparent;
}

.scrollable-tbody .form_select_user {
  padding: 10px;
  border-radius: 0;
}

.scrollable-tbody .dropdown button svg {
  margin: 0 !important;
}

.main_dashboardHeasder button {
  padding: 6px 8px;
  font-size: 20px;
}

.categoryList-tablewrap th {
  font-size: 16px;
  font-weight: 700;
}

.main_dashboardHeasder .text-secondary {
  color: #333333 !important;
  font-weight: 700;
  line-height: 40px;
  margin: 0;
}

.calender-main select:focus {
  box-shadow: none;
  border-color: var(--bs-border-color);
}

@media screen and (max-width: 1366px) {
  .date_range input {
    font-size: 14px;
  }

  .date_picker_styling {
    min-width: 270px !important;
  }

  .form_select_user {
    max-width: 100%;
  }
}

/* pre and next icon css */

.modal-navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #fff;
  /* Icon color */
  opacity: 0;
  /* Initially hidden */
  transition: opacity 0.3s ease;
  /* Fade-in/out animation */
}

.modal-navigation:hover {
  color: #ccc;
  /* Hover color */
}

.modal-navigation-icon {
  font-size: 24px;
  /* Icon size */
}

.modal-navigation.left {
  left: 10px;
  /* Adjust the left position */
}

.modal-navigation.right {
  right: 10px;
  /* Adjust the right position */
}

.modal-navigation.show {
  opacity: 1;
  /* Show icon */
}

.image-div-contaoner {
  max-width: 1350px;
}

/* Date range style  */

.daterangepicker .ranges li.active {
  background-color: #08c !important;
  color: #fff;
}

.daterangepicker .ranges li:hover {
  background: rgba(136, 121, 220, 0.1);
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #357ebd !important;
  border-color: transparent !important;
  color: #fff;
}

.daterangepicker .drp-calendar.left .calendar-table {
  padding-right: 8px;
  border-right: 1px solid #ddd;
  border-width: 1px;
  height: 350px;
}

.daterangepicker .ranges ul {
  width: 140px;
  border-right: 1px solid #ddd;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
}

.css-13cymwt-control {
  max-height: 22vh;
  overflow: auto;
}

.project-filter {
  max-height: 73vh;
}

.loader {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}