/* table,
 .divTable {
      border: 1px solid lightgray; 
      width : fit-content;
 } */

.tr {
  display: flex;
}

tr,
.tr {
  width: fit-content;
  height: 30px;
}

th,
.th,
td,
.td {
  /* box-shadow: inset 0 0 0 1px lightgray; */
  /* padding   : 0.25rem; */
  padding: 8px;
  font-size: 13px;
  color: #2a2e34;
}

th,
.th {
  padding: 2px 8px;
  position: relative;
  /* font-weight: bold; */
  font-weight: 500;
  text-align: left;
  height: 30px;
}

td,
.td {
  height: 30px;
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: blue;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}

.categoryList-tablewrap th,
.categoryList-tablewrap .th,
.categoryList-tablewrap td,
.categoryList-tablewrap .td {
  box-shadow: none;
}

.categoryList-tablewrap th,
.categoryList-tablewrap .th {
  border: 0;
  height: 40px;
}


.categoryList-tablewrap-multi th {
  min-width: 200px;
}

/* .categoryList-tablewrap td,
.categoryList-tablewrap .td,
.categoryList-tablewrap-multi td {
  border: 1px solid #cbcbcb;
} */

.categoryList-tablewrap:not(.categoryList-tablewrap-multi)
  tbody
  tr:first-child
  td {
  border-top: 0;
}

.categoryList-tablewrap tbody tr:hover {
  background: rgba(136, 121, 220, 0.1);
}

.categoryList-tablewrap tbody tr:hover td {
  color: var(--primary-btn);
}

.categoryList-tablewrap tbody tr:hover td .select__menu {
  color: #333333;
}

/* .categoryList-tablewrap tr th:first-child {
  border-top-left-radius: 8px;
  border-left: 1px solid rgb(203, 203, 203);
} */

.categoryList-tablewrap tr th:last-child {
  border-top-right-radius: 8px;
  /* width: 285px !important; */
  padding-right: 38px;
}

.categoryList-tablewrap,
.categoryList-tablewrap-multi {
  border: none;
  background: #ffffff;
}
.categoryList-tablewrap td {
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 100px;
  overflow: hidden;
  max-width: 0;
  vertical-align: middle;
}